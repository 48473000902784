import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import res from "../../img/backgraund-3.jpg";
import "./reservations.css";

const getAvailableTimes = (date) => {
  const openingTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0, 0, 0);
  const closingTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 15, 0, 0);
  
  const times = [];
  const currentTime = new Date();
  
  if (date.toDateString() === currentTime.toDateString()) {
    if (currentTime.getHours() > 23 || (currentTime.getHours() === 23 && currentTime.getMinutes() > 15)) {
      return times;
    }
    openingTime.setTime(Math.max(openingTime.getTime(), currentTime.getTime() + 15 * 60 * 1000));
  }

  while (openingTime <= closingTime) {
    times.push(openingTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    openingTime.setMinutes(openingTime.getMinutes() + 15);
  }

  return times;
};

const Reservations = () => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [step, setStep] = useState(1);

  const today = new Date();
  const minDate = today.toISOString().split('T')[0];

  const handleNextStep = () => {
    setStep(2);
  };

  const handlePrevStep = () => {
    setStep(1);
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="relative w-full h-96">
          <img
            className="absolute h-full w-full object-cover object-center"
            src={res}
            alt="nature"
          />
          <div className="absolute inset-0 h-full w-full bg-chako-700/25"></div>
          <div className="relative pt-28 text-center font-Montserrat font-black text-white">
            <h2 className="block text-3xl lg:text-4xl">RESERVA AHORA</h2>
          </div>
        </div>
        <div className="-mt-16 mb-8 px-8 ">
          <div className="container mx-auto">
            <div className="py-12 flex justify-center border border-white bg-white shadow-md shadow-black/5 saturate-200">
              <div className="my-8 grid gap-6 px-4 w-6/12">
                {step === 1 ? (
                  <Formik
                    initialValues={{
                      date: '',
                      people: '',
                      location: '',
                      time: ''
                    }}
                    validationSchema={Yup.object({
                      date: Yup.date().required('Required'),
                      people: Yup.number().min(1, 'Must be at least 1').required('Required'),
                      location: Yup.string().oneOf(['Terrace', 'Dining Room']).required('Required'),
                      time: Yup.string().required('Required')
                    })}
                    onSubmit={(values, { resetForm }) => {
                      console.log(values);
                      handleNextStep();
                    }}
                  >
                    {({ values, setFieldValue, resetForm }) => (
                      <Form>
                      <div className="flex flex-col gap-5">
                      <div className="flex flex-col" >
                        <label htmlFor="date">Date</label>
                        <Field
                          className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"
                          type="date"
                          name="date"
                          min={minDate}
                          onChange={(e) => {
                            setFieldValue('date', e.target.value);
                            const selectedDate = new Date(e.target.value);
                            setAvailableTimes(getAvailableTimes(selectedDate));
                          }}
                        />
                        <ErrorMessage name="date" component="div" />
                        </div>
                        </div>
                        <label htmlFor="people">Number of People</label>
                        <Field as="select" name="people"  className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500">
                          <option value="">Select</option>
                          {Array.from({ length: 20 }, (_, i) => i + 1).map((num) => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="people" component="div" />

                        <label htmlFor="location">Location</label>
                        <Field as="select" name="location" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500">
                          <option value="">Select</option>
                          <option value="Terrace">Terrace</option>
                          <option value="Dining Room">Dining Room</option>
                        </Field>
                        <ErrorMessage name="location" component="div" />

                        <label htmlFor="time">Time</label>
                        <Field as="select" name="time" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500">
                          <option value="">Select</option>
                          {availableTimes.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="time" component="div" />

                        <button type="submit" className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Next</button>
                        <button type="button" onClick={resetForm} className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Clear</button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <Formik
                    initialValues={{
                      firstName: '',
                      lastName: '',
                      email: '',
                      phone: '',
                      comments: '',
                      acceptTerms: false,
                      acceptMarketing: false,
                      acceptCommunications: false,
                    }}
                    validationSchema={Yup.object({
                      firstName: Yup.string().required('Required'),
                      lastName: Yup.string().required('Required'),
                      email: Yup.string().email('Invalid email address').required('Required'),
                      phone: Yup.string().required('Required'),
                      comments: Yup.string(),
                      acceptTerms: Yup.bool().oneOf([true], 'Must accept terms and conditions'),
                    })}
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                  >
                    {({ resetForm }) => (
                      
                      <Form>
                        <label htmlFor="firstName">First Name</label>
                        <Field type="text" name="firstName" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"/>
                        <ErrorMessage name="firstName" component="div" />

                        <label htmlFor="lastName">Last Name</label>
                        <Field type="text" name="lastName" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"/>
                        <ErrorMessage name="lastName" component="div" />

                        <label htmlFor="email" >Email</label>
                        <Field type="email" name="email" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"/>
                        <ErrorMessage name="email" component="div" />

                        <label htmlFor="phone">Phone</label>
                        <Field type="text" name="phone" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"/>
                        <ErrorMessage name="phone" component="div" />

                        <label htmlFor="comments">Comments</label>
                        <Field as="textarea" name="comments" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500"/>
                        <ErrorMessage name="comments" component="div" />
                        <div className="flex flex-col">
                        <label>
                          <Field type="checkbox" name="acceptTerms" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:outline-none" />
                          Acepto las condiciones de uso y privacidad
                        </label>
                        <ErrorMessage name="acceptTerms" component="div" />

                        <label>
                          <Field type="checkbox" name="acceptMarketing" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:outline-none"/>
                          Acepto el uso de mis datos por parte del restaurante para fines comerciales
                        </label>

                        <label>
                          <Field type="checkbox" name="acceptCommunications"  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:outline-none"/>
                          Acepto recibir comunicaciones del restaurante para fines comerciales
                        </label>
                        </div>

                        <button type="submit" className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Submit</button>
                        <button type="button" onClick={handlePrevStep} className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Cancelar reserva</button>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Reservations;
