import './App.css';
import {Routes, Route } from 'react-router'
import Home from './pages/Home'
import Nosotros from './pages/Nosotros'
import OrderNow from './pages/OrderNow'
import Reservations from './pages/Reservations'
import Restaurants from './pages/Restaurants'


function App() {
  return (<>
  <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/nosotros' element={<Nosotros/>} />
    <Route path='/order-now' element={<OrderNow/>} />
    <Route path='/reservations' element={<Reservations/>} />
    <Route path='/restaurants' element={<Restaurants/>} />
  </Routes>
  </>);
}

export default App;
