import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import './navbar.css';
import logo from '../../img/logo.png';
import logo2 from '../../img/logo-2.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
    <header className="h-20">
      <div className='h-20 bg-chako-700 py-3 fixed top-0 left-0 right-0 flex items-center justify-between pr-6 z-50'>
        <div className='absolute left-1/2 transform -translate-x-1/2 mt-5'>
          <img src={logo} alt="logo" />
        </div>
        <div className="ml-auto">
          <button onClick={() => setOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10 text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div className={` ${open ? 'translate-y-0' : '-translate-y-full'} fixed top-0 left-0 right-0 h-screen bg-white transition-transform duration-300  z-50`}>
          <div className="flex items-center">
            <div className="w-40 min-h-screen bg-chako-700 flex flex-row items-center">
              <button className="absolute top-4 left-12" onClick={() => setOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
              <img src={logo2} alt="" />
            </div>
            <nav className="text-3xl sm:text-5xl w-full flex flex-col font-Montserrat font-black text-chako-700 text-left">
              <NavLink to="/reservations" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">RESERVAR</NavLink>
              <NavLink to="/order-now" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">PIDE YA</NavLink>
              <NavLink to="/menu" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">CARTA</NavLink>
              <NavLink to="/" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">RESTAURANTES</NavLink>
            </nav>
          </div>
        </div>
      </div>
      </header>
    </>
  );
};

export default Navbar;


