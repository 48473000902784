import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import carta from "../../img/carta.jpg";
import axios from "axios";

const OrdenNow = () => {
  const [restaurantes, setRestaurantes] = useState([]);
  const [activeRestaurant, setActiveRestaurant] = useState(0);
  const [activeCategory, setActiveCategory] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get('http://localhost:3001/restaurantes')
      .then(response => {
        setRestaurantes(response.data);
        setActiveCategory(response.data[0]?.categorias[0] || null);
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });
  }, []);

  const selectRestaurant = (index) => {
    setActiveRestaurant(index);
    setActiveCategory(restaurantes[index].categorias[0]);
  };

  const selectCategory = (categoria) => {
    setActiveCategory(categoria);
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="relative w-full h-32 bg-chako-700">
          <div className="relative pt-10 text-center font-Montserrat font-black text-white">
            <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black italic uppercase text-3xl md:text-5xl text-white pt-20">
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="overflow-hidden w-full">
                    <div className="scroll-left">
                      <p className="inline-block">PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA</p>
                      <p className="inline-block">PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA</p>
                      <p className="inline-block">PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA</p>
                      <p className="inline-block">PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA PIDE AHORA</p>
                    </div>
                  </div>
                  </div>
                  </div>
          </div>
        </div>
        <div className="">
          <div className="w-full mx-auto">
              <div className="w-full">
                <div className="flex flex-col h-full bg-chako-700">
                  <div className="flex justify-around  p-4 border-b-4 border-t-4 border-white">
                    {restaurantes.map((restaurante, rIndex) => (
                      <button
                        key={rIndex}
                        className={`py-2 px-4 ${activeRestaurant === rIndex ? 'bg-white text-chako-700' : 'bg-chako-700 text-white'} font-Montserrat font-black rounded-3xl focus:outline-none`}
                        onClick={() => selectRestaurant(rIndex)}
                      >
                        {restaurante.nombre}
                      </button>
                    ))}
                  </div>
                  <div className="flex flex-1">
                    <div className="w-52 ml-2 border-r-4 border-white justify-center items-center">
                      {restaurantes[activeRestaurant]?.categorias.map((categoria, cIndex) => (
                        <button
                          key={cIndex}
                          className={`w-52 pr-2 py-2 mb-2 text-left ${activeCategory?.nombre === categoria.nombre ? 'bg-white text-chako-700' : 'bg-chako-700 text-white'} font-Montserrat font-black  focus:outline-none`}
                          onClick={() => selectCategory(categoria)}
                        >
                          {categoria.nombre}
                        </button>
                      ))}
                    </div>
                    <div className="flex p-4 gap-5 flex-wrap items-center justify-center">
                      {activeCategory?.productos.map((producto, pIndex) => (
                        <div key={pIndex} className="">
                          <div className="block rounded-xl bg-white w-72 font-Montserrat">
                            <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                              <img className="rounded-lg sm:h-64 md:h-64 w-full" src={producto.imagen} alt={producto.nombre} />
                              <a href="#!">
                                <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                              </a>
                            </div>
                            <div className="p-2">
                              <div className="flex justify-between">
                                <h5 className="mb-2 text-sm font-bold leading-tight text-neutral-800 dark:text-neutral-50">{producto.nombre}</h5>

                              </div>
                              <p className="mb-1 text-sm text-neutral-600 dark:text-neutral-200">{producto.descripcion}</p>
                              <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                                Precio: ${producto.precio}
                              </p>
                              <p className="mb-2 text-sm font-bold leading-tight text-neutral-800 dark:text-neutral-50">
                                {producto.delivery ? "Disponible para delivery" : "No disponible para delivery"}
                              </p>
                              <button className="bg-chako-700 w-full h-8 rounded-3xl text-white font-bold ">
                                Añadir al carrito
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            
          </div>

        </div>
        <button onClick={() => setOpen(true)} className="w-14 h-14 fixed bg-chako-700 hover:bg-white bottom-7 right-7 rounded-full text-center  flex justify-center items-center shadow-xl">
          <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 576 512"> <path fill="#ffffff " className="hover:" d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
        </button>
        <div className={` ${open ? 'translate-y-0' : '-translate-y-full'} bg-chako-700/50 backdrop-blur-sm fixed top-0 left-0 right-0 h-screen transition-transform duration-300 flex justify-center items-center`}>
          <button className="absolute top-20 left-12" onClick={() => setOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10 text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="flex bg-white z-50 w-80 h-80">
            <h2>Carito</h2>

          </div>
        </div>
      </section>
    </>
  );
};

export default OrdenNow;
